<template>
  <v-row class="reports" justify="center">
    <v-col style="max-width: 1024px" class="pt-0">
      <v-row align="center">
        <v-col cols="12" md="2">
          <v-select
            v-model="selectedYear"
            item-text="label"
            item-value="value"
            :items="years"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="selectedMonth"
            item-text="label"
            item-value="value"
            :items="months"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col class="d-none d-md-flex"></v-col>
        <v-col cols="12" md="2">
          <v-btn
            color="primary"
            large
            depressed
            block
            min-width="120"
            :loading="busy"
            @click="search"
          >
            <v-icon left>mdi-magnify</v-icon>Find
          </v-btn>
          <!-- <v-btn
              color="success"
              :disabled="!reports || busy"
              depressed
              @click="generateReport"
          >Download Invoice</v-btn>-->
        </v-col>
        <v-col>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn
              large
              block
              color="success"
              :disabled="!reports || busy"
              depressed
              @click="generateReport"
            >
            <v-icon left>mdi-microsoft-excel</v-icon>Download CSV
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <!-- All Patients -->
      <v-data-table
        v-if="reports"
        :headers="headerPatients"
        :items="reports"
        class="elevation-1"
        item-class="clickable"
        :items-per-page="100"
        :footer-props="{itemsPerPageOptions:[50, 100, 200, -1]}"
      >
        <template v-slot:item="{item}">
          <tr>
            <td>{{ item.ownerPatientId }}</td>
            <td>
              <div class="text-center" style="width: 40px">{{item.gender | gender}}</div>
            </td>
            <td>{{item.birthdate | date('MM/DD/YYYY')}}</td>
            <td>{{ item.birthdate | bday }}</td>
            <td>{{ item.firstName }} {{item.lastName}}</td>
          </tr>
        </template>
      </v-data-table>
      <v-row v-if="!reports" class="bg-hero-icon">
        <v-col>
          <v-row justify="center">
            <h1 class="font-weight-light" style="color: #d0d0d0">Invoices</h1>
          </v-row>
          <v-row justify="center">
            <v-icon color="grey lighten-2" size="220">mdi-receipt</v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.bg-hero-icon {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 950px) {
  .bg-hero-icon {
    position: relative;
    top: 30px;
    left: 0;
    transform: none;
  }
}
</style>
<style lang="scss">
.reports tr:hover {
  background-color: transparent !important;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.tooltip-cpt {
  background: white;
  color: #333;
  border: 1px solid #dddddd;
}
</style>

<script>
import moment from 'moment'
import replace from 'lodash/replace'
import upperFirst from 'lodash/upperFirst'
import lowerCase from 'lodash/lowerCase'
import { mapActions, mapState } from 'vuex'

const today = new Date()
const dateFormat = 'YYYY-MM-DD'
const numWeekDays = 7

export default {
  data: () => {
    return {
      headerPatients: [
        { text: 'Patient ID', value: 'patient', width: 120, sortable: false },
        { text: 'Gender', value: 'patient', width: 80, sortable: false },
        { text: 'Birth Date', value: 'patient', width: 120, sortable: false },
        { text: 'Age', value: 'patient', width: 64, sortable: false },
        { text: 'Patient Name', value: 'patient', sortable: false },
      ],
      busy: false,
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      selectedDateOption: 'custom',
      dateOptions: [
        { longName: 'Custom', shortName: 'custom' },
        { longName: 'Yesterday', shortName: 'yesterday' },
        // { longName: 'Last Week', shortName: 'last_week' },
        { longName: 'Last Month', shortName: 'last_month' },
        { longName: 'Month-to-Date', shortName: 'month_to_date' },
      ],
      reportFilter: 'patient',

      selectedYear: '2020',
      selectedMonth: '01',
      years: [{ label: '2020', value: '2020' }],
      months: [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('report', ['patientBillingReport', 'novoBillingReport']),
    isValidDateRange() {
      return true
    },
    isSearchEnabled() {
      return this.startDate || this.endDate
    },
    reports() {
      // if (this.reportFilter === 'patient') {
      //   return this.patientBillingReport
      // }
      return this.novoBillingReport
    },
  },
  filters: {
    date(val, format = 'YYYY-MM-DD') {
      return moment(val).format(format)
    },
    bday(val) {
      return moment().diff(val, 'years', false)
    },
    gender(val) {
      if (val.toLowerCase() === 'male') {
        return 'M'
      }
      return 'F'
    },
  },
  methods: {
    ...mapActions('report', ['getInvoice', 'downloadCSV']),
    allowedStartDates: (val) => {
      const yesterday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      return yesterday > moment(val, dateFormat)
    },
    allowedEndDates: (startDate) => {
      const sDate = moment(startDate, dateFormat).toDate()
      const endOfStartMonth = new Date(
        sDate.getFullYear(),
        sDate.getMonth() + 1,
        0
      )
      const day = new Date(
        sDate.getFullYear(),
        sDate.getMonth(),
        sDate.getDate()
      )
      const yesterday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      return (val) => {
        const eDate = moment(val, dateFormat)
        return day <= eDate && eDate <= endOfStartMonth && yesterday > eDate
      }
    },
    selectionChange(val) {
      let start = new Date()
      let end = new Date()
      switch (val) {
        case 'yesterday':
          start.setDate(start.getDate() - 1)
          end.setDate(end.getDate() - 1)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          break
        case 'last_week':
          start.setDate(start.getDate() - numWeekDays - start.getDay())
          end.setDate(end.getDate() - end.getDay() - 1)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          break
        case 'last_month':
          start.setMonth(end.getMonth() - 1)
          start.setDate(1)
          end.setDate(0)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          break
        case 'month_to_date':
          start.setDate(1)
          end.setDate(end.getDate() - 1)
          this.startDate = moment(start).format(dateFormat)
          this.endDate = moment(end).format(dateFormat)
          break
        default:
          this.startDate = null
          this.endDate = null
      }
    },
    generateReport() {
      let items = []
      this.reports.forEach((item) => {
        items.push({
          ownerPatientId: item.ownerPatientId,
          firstName: upperFirst(lowerCase(item.firstName)),
          lastName: upperFirst(lowerCase(item.lastName)),
          gender: replace(
            replace(item.gender, 'FEMALE', 'F'),
            'MALE',
            'M'
          ),
          birthdate: (item.birthdate + '').substr(0, 10),
        })
      })

      this.downloadCSV({
        title: `NovoRPM Invoice Report -- ${this.selectedMonth}-${this.selectedYear}`,
        headers: {
          ownerPatientId: 'Patient ID',
          firstName: 'First Name',
          lastName: 'Last Name',
          gender: 'Gender',
          birthdate: 'Birth Date',
        },
        items,
      })
    },
    async search() {
      this.busy = true
      let numDaysInMonth = new Date(
        this.selectedYear,
        this.selectedMonth,
        0
      ).getDate()
      await this.getInvoice({
        startDate: `${this.selectedYear}-${this.selectedMonth}-01`,
        endDate: `${this.selectedYear}-${this.selectedMonth}-${numDaysInMonth}`,
      })
      this.busy = false
    },
  },
  watch: {},
  created() {
    this.years = []
    let currentYear = new Date().getFullYear()
    for (let year = 2020; year <= currentYear; year++) {
      this.years.push({ label: year.toString(), value: year.toString() })
    }

    let now = new Date()
    now.setMonth(now.getMonth())

    this.selectedMonth = (now.getMonth() + 1).toString().padStart(2, '0')
    this.selectedYear = now.getFullYear().toString()
  },
}
</script>